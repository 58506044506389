/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('#password-form-toggle').click(function(event) {
          event.preventDefault();
          $(this).toggle();
          $('#password-form').toggle();
          $('#password-message').toggle();
        });

         $(function(){


            // subject-group material-group av-group


            // get all selected data-slugs
            // the first-child in each dropdown
            //
            var vars = [], hash;
                var q = document.URL.split('?')[1];
                if(q != undefined){
                    q = q.split('&');
                    for(var i = 0; i < q.length; i++){
                        hash = q[i].split('=');
                        vars.push(hash[1]);
                        vars[hash[0]] = hash[1];
                    }
            }


            $(".dropdown-menu li a").click(function(){

              var first_slug = $(this).data('slug');
              var first_tax = $(this).data('tax');

              vars[first_tax] = first_slug;

              if (! vars['animal']) {
                vars['animal'] = ''
              }

              if (! vars['material']) {
                vars['material'] = ''
              }

              if (! vars['status']) {
                vars['status'] = ''
              }

              var all_args = "/portfolio/?pslugs=true&animal=" + vars['animal'] + "&material=" + vars['material'] + "&status=" + vars['status'];



              window.location.href = all_args;


              // $(".btn:first-child").text($(this).text());
              // $(".btn:first-child").val($(this).text());

           });

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
